export const environment = {
  production: false,
  API_ENDPOINT: 'https://dwmapi.azurewebsites.net/',
};

export const firebaseEnvironment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCFKQXQRPEo20t3yBo5g8pjjNVwZc_B9DQ',
    projectId: 'dwn---dev',
    storageBucket: 'dwn---dev.appspot.com',
    messagingSenderId: '1013853906851',
    appId: '1:1013853906851:web:30f224863d1092282d1c16',
    authDomain: 'dwn---dev.firebaseapp.com',
    databaseURL:
      'https://dwn---dev-default-rtdb.europe-west1.firebasedatabase.app',
  },
};

export const paymentUrl =
  'https://mdepayments.epdq.co.uk/ncol/test/orderstandard_utf8.asp';

export const instrumentationKey = 'df2241b5-fbf9-4c3a-9971-2f4aabad120b';
