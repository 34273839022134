<div class="container-fluid">
  <div class="helpedCustomerDiv border">
    <h1 class="text-uppercase">We Have Helped Customers Just Like You Save Money</h1>
    <p>
      We help people just like you save money on their business and domestic bills, saving hundred's of £'s a year.
    </p>
    <button mat-flat-button color="primary" [routerLink]="[ '/signup' ]" *ngIf="!isUser()">Join Today</button>
  </div>
</div>

<div class="footerDiv">
  <div class="row mx-0">
    <div class="col-lg-4 mt-3 info">
      <h1>Don’t Waste Money</h1>
      <p>
        Don't Waste Money is one of the leading energy comparison services. We can help you switch energy suppliers now
        and save money on your household bills!
      </p>
      <div class="social d-flex">
        <div class="fb me-2">
          <a class="icon-anim-container" href="https://www.facebook.com/Dont-Waste-Money-105102933291581"
            target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/facebook.png" class="icon-anim-img">
          </a>
        </div>
        <div class="twitter mx-2">
          <a class="icon-anim-container" href="https://twitter.com/dontwastemoney_" target="_blank"
            rel="noopener noreferrer">
            <img src="/assets/images/twitter.png" class="icon-anim-img">
          </a>
        </div>
        <div class="linkedin mx-2">
          <a class="icon-anim-container" href="https://www.linkedin.com/company/10587348" target="_blank"
            rel="noopener noreferrer">
            <img src="/assets/images/linkedin.png" class="icon-anim-img">
          </a>
        </div>
        <div class="insta mx-2">
          <a class="icon-anim-container" href="https://www.instagram.com/dontwastemoney" target="_blank"
            rel="noopener noreferrer">
            <img src="/assets/images/instagram.png" class="icon-anim-img">
          </a>
        </div>
        <div class="youtube ms-2">
          <a class="icon-anim-container" href="https://www.youtube.com/channel/UCvGMb9ci0brAVJEwzP1zPSA" target="_blank"
            rel="noopener noreferrer">
            <img src="/assets/images/youtube.png" class="icon-anim-img">
          </a>
        </div>
      </div>
    </div>
    <div class="col-lg-4 mt-3 links">
      <h1>Useful Info</h1>
      <p><a [routerLink]="[ '/services/energy' ]">Domestic Energy Market Explained</a></p>
      <p><a [routerLink]="[ '/services/energy' ]">Energy Saving Tips</a></p>
      <p><a [routerLink]="[ '/news' ]">Energy News</a></p>
      <p><a [routerLink]="[ '/career' ]">Careers</a></p>
      <p><a [routerLink]="[ '/privacy-policy' ]">Privacy Policy</a></p>
      <p><a [routerLink]="[ '/terms-service' ]">Terms and Conditions</a></p>
    </div>
    <div class="col-lg-4 mt-3 addressCol">
      <h1>Legal Notices</h1>
      <p>© 2020 The Energy Comparison Service Limited - Company Registered in the UK, No. 09781679</p>
      <p>Office address: DWM, 2nd Floor, 3- 5 St Paul's Square, Birmingham, B3 1QU</p>
      <p>Registered address: DWM, 2nd Floor, 3- 5 St Paul's Square, Birmingham, B3 1QU</p>
      <p>Telephone: 0121 803 2020</p>
    </div>
  </div>
</div>