import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppApi } from '../shared/services/app-api';

@Injectable()
export class OffersService {
  constructor(
    private http: HttpClient
  ) { }

  getOffers(params?: any) {
    return this.http.get(AppApi.OFFERS, { params: params });
  }

  offerClick(id: string) {
    return this.http.post(AppApi.OFFER_CLICK(id), {});
  }
}
