<header>
  <div class="top-header text-center bg-light-primary py-2">
    <div class="float-end">
      <div class="social d-flex me-3">
        <div class="fb me-2">
          <a class="icon-anim-container" href="https://www.facebook.com/Dont-Waste-Money-105102933291581"
            target="_blank" rel="noopener noreferrer">
            <img src="/assets/images/facebook.svg" class="icon-anim-img">
          </a>
        </div>
        <div class="twitter mx-2">
          <a class="icon-anim-container" href="https://twitter.com/dontwastemoney_" target="_blank"
            rel="noopener noreferrer">
            <img src="/assets/images/twitter.svg" class="icon-anim-img">
          </a>
        </div>
        <div class="linkedin mx-2">
          <a class="icon-anim-container" href="https://www.linkedin.com/company/10587348" target="_blank"
            rel="noopener noreferrer">
            <img src="/assets/images/linkedin.svg" class="icon-anim-img">
          </a>
        </div>
        <div class="insta mx-2">
          <a class="icon-anim-container" href="https://www.instagram.com/dontwastemoney" target="_blank"
            rel="noopener noreferrer">
            <img src="/assets/images/instagram.svg" class="icon-anim-img">
          </a>
        </div>
        <div class="youtube ms-2">
          <a class="icon-anim-container" href="https://www.youtube.com/channel/UCvGMb9ci0brAVJEwzP1zPSA" target="_blank"
            rel="noopener noreferrer">
            <img src="/assets/images/youtube.svg" class="icon-anim-img">
          </a>
        </div>
      </div>
    </div>
    <div class="mx-auto" style="width: fit-content;">
      EXCELLENT 4.4 <mat-icon class="align-bottom">star_rate</mat-icon> 1225 REVIEWS ON <mat-icon [color]="'primary'"
        class="align-bottom">star_rate
      </mat-icon> <span class="fw-bold">Trustpilot</span>
    </div>
  </div>
  <nav class="navbar navbar-expand-lg navbar-light bg-white border-bottom">
    <div class="container">
      <a class="navbar-brand logo" routerLink="/"><img src="assets/images/logo.png" alt="DWM"></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#dwmNavbar"
        aria-controls="dwmNavbar" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="dwmNavbar" #nav>
        <ul class="navbar-nav mx-auto">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/home']" [routerLinkActive]="['active']" (click)="closeNav()">Home</a>
          </li>
          <li class="nav-item dropdown" [routerLinkActive]="['active']">
            <a class="nav-link dropdown-toggle" id="srvNavbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Services
            </a>
            <ul class="dropdown-menu p-0 p-lg-2" aria-labelledby="srvNavbarDropdown">
              <div class="row mx-0 d-none d-lg-flex">
                <li class="col-lg-3 p-0">
                  <a class="dropdown-item text-wrap icon-anim-container" [routerLink]="['/services/energy']">
                    <img loading="lazy" src="assets/images/domesticEnergy.svg" alt="Domestic Energy"
                      class="my-3 icon-anim-img">
                    <h3 class="mb-1">Energy</h3>
                    <p>We take the stress out of switching energy suppliers - making a complicated process easier.
                    </p>
                  </a>
                </li>
                <li class="col-lg-3 p-0">
                  <a class="dropdown-item text-wrap icon-anim-container" [routerLink]="['/services/broadband-contracts']">
                    <img loading="lazy" src="assets/images/broadbandcontracts.svg" alt="Broadband Contracts"
                      class="my-3 icon-anim-img">
                    <h3 class="mb-1">Broadband Contracts</h3>
                    <p>Helping you find the best deals with our award winning broadband services.
                    </p>
                  </a>
                </li>
                <li class="col-lg-3 p-0">
                  <a class="dropdown-item text-wrap icon-anim-container" [routerLink]="['/services/insurance']">
                    <img loading="lazy" src="assets/images/insurance.svg" alt="Insurance" class="my-3 icon-anim-img">
                    <h3 class="mb-1">Insurance</h3>
                    <p>Compare quotes across a variety of types of insurance and start saving money today. </p>
                  </a>
                </li>
                <li class="col-lg-3 p-0">
                  <a class="dropdown-item text-wrap icon-anim-container" [routerLink]="['/services/fundraising']">
                    <img loading="lazy" src="assets/images/fundraising-icon.svg" alt="Dept Management"
                      class="my-3 icon-anim-img">
                    <h3 class="mb-1">Fundraising</h3>
                    <p> We are passionate about charities, delivering ethical Face to Face fundraising solutions across venues and events, as well as the residential marketplace. </p>
                  </a>
                </li>
                <li class="col-lg-3 p-0">
                  <a class="dropdown-item text-wrap icon-anim-container" [routerLink]="['/services/solar-panel']">
                    <img loading="lazy" src="assets/images/broadbandcontracts.svg" alt="Broadband Contracts"
                      class="my-3 icon-anim-img">
                    <h3 class="mb-1">Solar Panel</h3>
                    <p>Reduce your household bills and get upto 4 solar PV quotes in one place - no obligation
                    </p>
                  </a>
                </li>
              </div>
              <div class="text-end d-none d-lg-block">
                <button class="know-more px-4 mt-3" mat-flat-button color="primary" fxShow="false" fxShow.gt-sm
                  [routerLink]="['/services']">
                  All Services
                </button>
              </div>
              <div class="d-lg-none ps-2">
                <li><a class="dropdown-item" [routerLink]="['/services/energy']" (click)="closeNav()">Energy</a></li>
                <li><a class="dropdown-item" [routerLink]="['/services/broadband-contracts']" (click)="closeNav()">Broadband
                    Contracts</a></li>
                <li><a class="dropdown-item" [routerLink]="['/services/insurance']" (click)="closeNav()">Insurance</a>
                </li>
                <li><a class="dropdown-item" [routerLink]="['/services/debt-management']" (click)="closeNav()">Debt
                    Management</a></li>
                <li><a class="dropdown-item text-primary fw-bold" [routerLink]="['/services']" (click)="closeNav()">All
                    Services</a></li>
              </div>
            </ul>
          </li>
          <li class="nav-item dropdown" [routerLinkActive]="['active']">
            <a class="nav-link dropdown-toggle" id="offNavbarDropdown" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Offers
            </a>
            <ul class="dropdown-menu p-0 p-lg-2 offers" aria-labelledby="offNavbarDropdown"
              [ngStyle]="{'left': leftStyle}">
              <div class="d-flex mx-0 d-none d-lg-flex">
                <ng-container *ngIf="offers.length > 0; else noPinned">
                  <li class="p-0 item curs-ptr scale-container" *ngFor="let o of offers">
                    <a class="dropdown-item text-wrap" (click)="openURL(o.url, o.id)">
                      <div class="text-center">
                        <img loading="lazy" src="{{o.filePath}}" class="my-3 scale-obj">
                      </div>
                      <h2 class="mb-1">{{o.title}}</h2>
                      <p>{{o.shortDescription}}</p>
                    </a>
                  </li>
                </ng-container>
                <ng-template #noPinned>
                  <h3 class="mb-0 mx-4 mt-2" style="width: 300px;">We don't have any pinned offers, please continue
                    viewing all our offers.</h3>
                </ng-template>
              </div>
              <div class="text-end d-none d-lg-block">
                <button class="know-more px-4 mt-3" mat-flat-button color="primary" fxShow="false" fxShow.gt-sm
                  [routerLink]="['/offers']">
                  All Offers
                </button>
              </div>
              <div class="d-lg-none ps-2">
                <ng-container *ngIf="offers.length > 0; else elseTemplate">
                  <li *ngFor="let o of offers">
                    <a class="dropdown-item" (click)="openURL(o.url, o.id)">{{o.title}}</a>
                  </li>
                </ng-container>
                <ng-template #elseTemplate>
                  <li class="text-center">No Pinned Offers</li>
                </ng-template>
                <li><a class="dropdown-item text-primary fw-bold" [routerLink]="['/offers']" (click)="closeNav()">All
                    Offers</a>
                </li>
              </div>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/rewards']" [routerLinkActive]="['active']"
              (click)="closeNav()">Rewards</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/career']" [routerLinkActive]="['active']"
              (click)="closeNav()">Careers</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/news']" [routerLinkActive]="['active']"
              (click)="closeNav()">News/Media</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/contact-us']" [routerLinkActive]="['active']"
              (click)="closeNav()">Contact</a>
          </li>
        </ul>
        <ng-container *ngIf="!isToken; else loggedIn">
          <div class="d-lg-flex d-block mt-2 mt-lg-0">
            <button mat-stroked-button color="primary" fxShow="false" fxShow.gt-sm [routerLink]="['/signin']"
              class="w-100" (click)="closeNav()">
              Sign In
            </button>
            <button mat-flat-button color="primary" fxShow="false" fxShow.gt-sm [routerLink]="['/signup']"
              class="w-100 mt-3 mt-lg-0 ms-lg-3" (click)="closeNav()">
              Sign Up
            </button>
          </div>
        </ng-container>
        <ng-template #loggedIn>
          <ul class="navbar-nav">
            <li class="nav-item dropdown align-self-lg-center">
              <a class="nav-link notif mr-2 p-lg-1" id="notifDD" role="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                <mat-icon class="align-bottom d-none d-lg-block">notifications</mat-icon>
                <div class="d-lg-none dropdown-toggle">Notifications</div>
                <div class="dot p-1 rounded-circle bg-danger" *ngIf="!allRead"></div>
              </a>
              <div class="dropdown-menu dropdown-menu-end" aria-labelledby="notifDD"
                style="width: 17rem; right: 0 !important; max-height: 300px; overflow-y: auto;"
                (click)="$event.stopPropagation()">
                <ng-container *ngIf="notif.length > 0; else noNotif">
                  <a class="dropdown-item curs-ptr d-flex" [ngClass]="{'bg-light-primary': !n.markAsRead}"
                    *ngFor="let n of notif">
                    <div>
                      <p class="mb-0 fw-bold text-wrap" style="font-size: 14px;">{{n.title}}</p>
                      <p class="mb-0 text-wrap" style="font-size: 12px;" [innerHTML]="n.body"></p>
                    </div>
                    <mat-icon class="ms-auto" *ngIf="!n.markAsRead" (click)="markNotif(n.id)">close</mat-icon>
                  </a>
                </ng-container>
                <ng-template #noNotif>
                  <a class="dropdown-item">
                    You have no notifications
                  </a>
                </ng-template>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle align-items-center d-flex p-lg-1" id="navbarDropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                <div class="bg-primary rounded-circle p-2 text-white me-2 d-none d-lg-block text-uppercase text-center">
                  {{user && user.fn && user.ln ? user.fn.split('')[0] + user.ln.split('')[0]: 'N/A'}}</div>
                {{user && user.fn && user.ln? user.fn + ' ' + user.ln : 'N/A'}}
              </a>
              <ul class="dropdown-menu dropdown-menu-end py-0 py-lg-2" aria-labelledby="navbarDropdown">
                <div class="ps-2 ps-lg-0">
                  <li><a class="dropdown-item curs-ptr" [routerLink]="['/account']" (click)="closeNav()">My Account</a>
                  </li>
                  <li><a class="dropdown-item curs-ptr" (click)="signout()">Sign out</a></li>
                </div>
              </ul>
            </li>
          </ul>
        </ng-template>
      </div>
    </div>
  </nav>
</header>
