import { Component, OnInit } from '@angular/core';
import { TokenDataService } from '../../shared/services/token-data.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(
    private tokenDataServ: TokenDataService
  ) { }

  ngOnInit(): void { }

  isUser(): boolean {
    return this.tokenDataServ.getToken() ? true : false;
  }
}
