import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AppApi } from './app-api';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService {

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private http: HttpClient
  ) { }

  requestPermission(userId: string) {
    this.angularFireMessaging.requestToken.subscribe((token) => {
      if (token != null) {
        let data = {
          customerId: userId,
          token: token,
          deviceName: "WebBrowser"
        };
        this.saveToken(data).subscribe(result => { });
      }
    });
  }

  saveToken(data: any) {
    return this.http.post(AppApi.NOTIF_SUB, data);
  }

  getAllNotif(id: any) {
    return this.http.get(AppApi.GET_NOTIF + '/' + id);
  }

  markNotif(id: any) {
    return this.http.put(AppApi.MARK_NOTIF + '/' + id, {});
  }
}
