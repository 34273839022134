import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { instrumentationKey } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  excUrl = ['signin', 'signup', 'lifestyle-questionnaire', 'get-rewards', 'third-party-contact', 'forgot-password', 'reset-password', 'adt-offer', 'confirm-email', 'confirm-account', 'campaign'];
  loading: boolean = true;
  constructor(
    private loc: Location
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = false;
    }, 2500);

    // azure application insights
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        enableRequestHeaderTracking: true
      }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }

  isAuthPage(): boolean {
    return (this.excUrl.filter(u => this.loc.path().indexOf(u) !== -1).length > 0);
  }
}
