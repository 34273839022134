import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenDataService } from '../services/token-data.service';

@Injectable({
  providedIn: 'root'
})
export class FirstLoginGuard implements CanActivate {
  constructor(public router: Router, private tokenDataServ: TokenDataService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.tokenDataServ.getToken() && this.tokenDataServ.getUser().firstLogin)
      return true;
    else {
      this.router.navigate(['/signin']);
      return false;
    }
  }

}
