import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class TokenDataService {
  user = new BehaviorSubject({});
  userInfo = this.user.asObservable();

  constructor(
    private router: Router,
    private cookieServ: CookieService
  ) { }

  setDynUser(user: string) {
    this.user.next(user);
  }

  setExpiry() {
    const dateNow = new Date();
    dateNow.setFullYear(dateNow.getFullYear() + 1);
    this.cookieServ.set('dwmExpiry', JSON.stringify({ date: dateNow }), undefined, '/');
  }

  getToken() {
    // this.setDynUser(this.getUser());
    // let token = this.cookieServ.get('dwmAcsTkn');
    // if (!token)
    //   return localStorage.getItem('dwmAcsTkn');
    // return this.cookieServ.get('dwmAcsTkn');    
    return localStorage.getItem('acsTkn');
  }

  removeAll() {
    this.remTkn();
    this.router.navigate(['/signin']);
  }

  remTkn() {
    localStorage.clear();
    this.cookieServ.deleteAll('/');
    this.setDynUser('');
  }

  setToken(res: any) {
    // let date = this.cookieServ.get('dwmExpiry');
    // if (date && JSON.parse(date)) {
    //   const dateNow = new Date(JSON.parse(date).date);
    //   this.cookieServ.set('dwmAcsTkn', res.access_token || '', dateNow, '/');
    // }    
    localStorage.setItem('acsTkn', res.access_token || '');
  }

  setUser(res: any) {
    this.setExpiry();
    let user: any = {
      id: res.userId,
      fn: res.customer.firstName,
      ln: res.customer.lastName,
      email: res.customer.email,
      plan: res.customer.generalInfo.subscriptionLevel,
      firstLogin: res.customer.isFirstTimeLogin,
      consent: res.customer.consent,
      amount: res.customer.generalInfo.amount,
      renewalDate: res.customer.generalInfo.renewalDate
    };
    // let date = this.cookieServ.get('dwmExpiry');
    // if (date && JSON.parse(date)) {
    //   const dateNow = new Date(JSON.parse(date).date);
    //   this.cookieServ.set('dwmUser', JSON.stringify(user), dateNow, '/');
    // }
    this.setDynUser(user);  
    this.cookieServ.set('dwmUser', JSON.stringify(user), undefined, '/');
  }

  getUser() {
    let user = this.cookieServ.get('dwmUser');
    // if (user && JSON.parse(user))
    //   return JSON.parse(user) || null;
    // else {
    //   let userLoc = localStorage.getItem('dwmUser');
    //   if (userLoc && JSON.parse(userLoc))
    //     return JSON.parse(userLoc) || null;
    //   else
    //     return null;
    // }
    try {
      if (user && JSON.parse(user))
        return JSON.parse(user) || null;
      else {
          return null;
      }
    } catch (ex) {
      return null;
    }
  }

  changeUser(res?: any) {
    let user = this.getUser();
    if (res) {
      user.fn = res.firstName;
      user.ln = res.lastName;
      user.email = res.email;
      user.plan = res.subscriptionLevel;
      user.consent = res.consent;
    } else
      user.plan = 2;
    this.cookieServ.set('dwmUser', JSON.stringify(user), undefined, '/');
    this.setDynUser(user);
  }

  setDataInLocal() {
    let token = this.cookieServ.get('dwmAcsTkn');
    localStorage.setItem('dwmAcsTkn', token);
    let user = this.cookieServ.get('dwmUser');
    localStorage.setItem('dwmUser', user);
    let exp = this.cookieServ.get('dwmExpiry');
    localStorage.setItem('dwmExpiry', exp);
  }

  setDataInCookie() {
    let token = this.cookieServ.get('dwmAcsTkn');
    if (!token) {
      let date = localStorage.getItem('dwmExpiry');
      if (date && JSON.parse(date)) {
        const dateNow = new Date(JSON.parse(date).date);
        this.cookieServ.set('dwmAcsTkn', localStorage.getItem('dwmAcsTkn') || '', dateNow, '/');
        this.cookieServ.set('dwmUser', localStorage.getItem('dwmUser') || '', dateNow, '/');
        this.cookieServ.set('dwmExpiry', date, undefined, '/');
      }
    }
    localStorage.clear();
  }
}
