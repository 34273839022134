export class AppApi {
  // ---- auth ---- //
  public static TOKEN = 'connect/token';

  public static SIGNIN = 'api/account/login';

  public static SIGNUP = 'api/app/customer/registration';

  public static FORGOT_PSWD = 'api/app/account/forgotPassword';

  public static RESET_PSWD = 'api/app/account/confirmResetPassword';

  public static CONFIRM_EMAIL = 'api/app/account/confirmEmail';

  // ---- contact ---- //
  public static CONTACT = 'api/app/contact';

  // ---- offers ---- //
  public static OFFERS = 'api/app/offer/publicOffers';

  public static OFFER_CLICK = (id: string) => `api/app/offer/${id}/click`;

  // ---- fizz ---- //
  public static FIZZ_RETAILERS = 'api/app/offer/GetCashbackOfferRetailers';

  public static FIZZ_CATEGORIES = 'api/app/offer/GetCashbackOfferCategories';

  public static FIZZ_OFFERS = 'api/app/offer/GetOffersByRetailerId';

  public static FIZZ_LINK = 'api/app/offer/GetOfferRedirectUrl';

  // ---- account ---- //
  public static ACCOUNT = 'api/app/customer/account';

  public static GET_LIFE_ANS = 'api/app/customer/answeredQuestions';
  
  public static GET_SAVINGS_OFR = '/api/app/offer/get-customer-savings';

  // ---- jobs ---- //
  public static APPLY_JOB = 'api/app/jobApplication';

  public static GET_JOBS = 'api/app/job';

  public static GET_JOB = (id: string) => `api/app/job/${id}`;

  // ---- third party ---- //
  public static THIRD_PARTY_CONTACT = (id: string) => `api/app/thirdPartyContact/${id}`;

  // ---- lifestyle questionnaire ---- //
  public static GET_LIFE_QUEST = 'api/app/lifeStyleQuestion?MaxResultCount=1000';

  public static SAVE_LIFE_ANS = 'api/app/lifeStyleAnswer/submit';

  // ---- get rewards ---- //
  public static CHECK_ENERGY = 'api/app/customer/energyCustomer';

  // ---- payment ---- //
  public static GET_PAY_INFO = 'api/app/payment/paymentInfo';

  public static SAVE_PAY_INFO = 'api/app/payment/savePaymentInfo';

  public static SEND_PAY_INFO = (order: string, status: string) => `api/app/payment/paymentInfoByOrderId/${order}?status=${status}`;

  // ---- blog/news ---- //
  public static GET_ALL_NEWS = 'api/app/blog?MaxResultCount=1000';

  public static GET_NEWS = (slug: string | null) => `api/app/blog/getBySlug/${slug}`;

  // ---- notification ---- //
  public static NOTIF_SUB = 'api/app/NotificationCustomerSubscription/Save';

  public static GET_NOTIF = 'api/app/Notification/NotificationList';

  public static MARK_NOTIF = 'api/app/notification/markAsRead';

  // ---- campaign ---- //
  public static CAMP = 'api/app/campaign/click';

  // ---- solar panel ---- //
  public static SOL_PANEL_QU = 'api/app/Quote/create';
}
