<div class="loading-screen" *ngIf="loading">
  <div class="page-loading">
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 235 228" *ngFor="let i of [1,2,3,4,5]">
      <g id="Group_3" data-name="Group 3" transform="translate(-330 -232)">
        <g id="Group_2" data-name="Group 2">
          <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(349 252)" fill="#7ccc44" stroke="#7ccc44"
            stroke-width="1">
            <ellipse cx="98.5" cy="94" rx="98" ry="93.5" stroke="none" />
            <ellipse cx="98.5" cy="94" rx="98" ry="93.5" fill="none" />
          </g>
          <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(349 252)" fill="none" stroke="#7ccc44"
            stroke-width="8">
            <ellipse cx="98.5" cy="94" rx="113.5" ry="110" stroke="none" />
            <ellipse cx="98.5" cy="94" rx="113.5" ry="110" fill="none" />
          </g>
          <text id="_" data-name="£" transform="translate(409 267)" fill="#fff" font-size="138"
            font-family="Arial-BoldMT, Arial" font-weight="700" letter-spacing="0.012em">
            <tspan x="0" y="125">£</tspan>
          </text>
        </g>
      </g>
    </svg>
  </div>
</div>
<app-progress-bar></app-progress-bar>
<app-header *ngIf="!isAuthPage()"></app-header>
<router-outlet></router-outlet>
<footer *ngIf="!isAuthPage()"></footer>