import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenDataService } from '../services/token-data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthCheckGuard implements CanActivate {
  constructor(public router: Router, private tokenDataServ: TokenDataService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.tokenDataServ.getToken()) {
      if (route.fragment)
        this.router.navigate(['/signin'], { queryParams: { redirectTo: route.routeConfig!.path, frag: route.fragment } });
      else
        this.router.navigate(['/signin'], { queryParams: { redirectTo: route.routeConfig!.path } });
      return false;
    } else
      return true;
  }

}
