import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenDataService } from '../services/token-data.service';
import { HttpHelper } from '../services/http-helper';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  rout: any;
  constructor(private router: Router, private toastr: ToastrService, private tokenDataServ: TokenDataService) {
    this.rout = router;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.errorHandler(error)
        })
      )
  }

  errorHandler(error: HttpErrorResponse) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/; // if there is chunk loading error
    console.log(chunkFailedMessage.test(error.message));
    console.log(error.message);
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else if (!window.navigator.onLine) {
      this.toastr.clear();
      this.toastr.error('An internet connection is needed', 'No Internet');
    } else if (!error.status) {
      this.toastr.clear();
      this.toastr.error('Could not connect to server', 'Connection Failed');
    } else {
      if (error.status == 401) {
        this.toastr.clear();
        this.toastr.error('You are not authorized, please login', 'Unauthorized Access');
        this.tokenDataServ.removeAll();
      }
      // status code 403 is wrongly used at backend
      // else if (error.status == 403) { 
      //   this.toastr.clear();
      //   this.toastr.error(error.error ? error.error.message : 'You don\'t have the access rights', 'Restricted Access');
      //   this.router.navigate(['/signin']);
      // }
      else {
        if (error.error) {
          if (error.error.error.message.indexOf('User name') >= 0 && error.error.error.message.indexOf('Email') >= 0)
            this.toastr.error(error.error.error.message.split(', ')[1], 'Error!');
          else
            this.toastr.error(HttpHelper.errMessage(error), 'Error!');
        }
        else
          this.toastr.error(error.message || "Please try after some time", "Error!");
      }
    }
    return throwError(error);
  }
}
