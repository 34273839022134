import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TokenDataService } from '../services/token-data.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private tokenDataServ: TokenDataService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url) {
      let url = `${environment.API_ENDPOINT}${request.url}`;
      let headerParams: any = {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache, no-store, must-revalidate',
        'If-Modified-Since': '0'
      };
      let incUrls = ['publicOffers', 'account', 'lifeStyleQuestion', 'lifeStyleAnswer', 'payment', 'answeredQuestions', 'offer', 'campaign', 'energyCustomer', 'Notification', 'notification'];
      if (incUrls.filter(sUrl => request.url.includes(sUrl)).length > 0) {
        headerParams = {
          ...headerParams,
          "Authorization": "Bearer " + (this.tokenDataServ.getToken() || '')
        }
      }
      request = request.clone({
        setHeaders: headerParams,
        url
      });
    }
    return next.handle(request);
  }
}
