import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { TokenDataService } from '../../shared/services/token-data.service';
import { OffersService } from 'src/app/offers/offers.service';
import { PushNotificationService } from '../../shared/services/push-notification.service';
import { instrumentationKey } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isToken!: boolean; user: any;
  offers!: any[]; leftStyle!: string;
  notif!: any[]; allRead!: boolean;

  constructor(
    private tokenDataServ: TokenDataService,
    private toastr: ToastrService,
    private router: Router,
    private offersSrv: OffersService,
    private pushNotification: PushNotificationService,
    private angularFireMessaging: AngularFireMessaging
  ) { }

  ngOnInit(): void {
    this.initialiseState();
  }

  initialiseState() {
    this.isToken = false;
    this.notif = [];
    this.offers = [];
    this.leftStyle = '-132px';
    this.getTokenUser();
    this.getOffers();
  }

  closeNav() {
    let nav = document.getElementById('dwmNavbar');
    if (nav!.classList.contains('show'))
      nav!.classList.remove('show');
  }

  getAllNotif() {
    if(this.user && this.user.id) {
      this.pushNotification.getAllNotif(this.user.id).subscribe((data: any) => {
        if (data && data.items && Array.isArray(data.items) && data.items.length > 0) {
          this.notif = data.items;
          this.checkReadNotif();
        }
      });
    }
  }

  checkReadNotif() {
    this.allRead = true;
    this.notif.forEach(n => {
      if (!n.markAsRead) this.allRead = false;
    });
  }

  getOffers() {
    this.offersSrv.getOffers({ isPinned: true }).subscribe((data: any) => {
      if (data && data.items && Array.isArray(data.items) && data.items.length > 0) {
        this.offers = data.items;
        switch (this.offers.length) {
          case 1:
            this.leftStyle = '-35px';
            break;
          case 2:
            this.leftStyle = '-155px';
            break;
          case 3:
            this.leftStyle = '-200px';
            break;
          case 4:
            this.leftStyle = '-308px';
            break;
          default:
            this.leftStyle = '0px';
        }
      }
    });
  }

  getTokenUser() {
    this.isToken = this.tokenDataServ.getToken() ? true : false;
    if (this.isToken) {
      this.tokenDataServ.userInfo.subscribe(newUser => {
        this.user = newUser;
        if(this.user && !this.user.id) {
          this.user = this.tokenDataServ.getUser();
        }
      });
      this.getAllNotif();
      if(this.user)
        this.pushNotification.requestPermission(this.user.id);
      this.angularFireMessaging.messages.subscribe((payload: any) => {
        this.getAllNotif();
        this.toastr.info(payload.notification.body, payload.notification.title, { timeOut: 4000 });
      });
      navigator.serviceWorker.addEventListener('message', (event) => {
        this.getAllNotif();
        this.toastr.info(event.data.notification.body, event.data.notification.title, { timeOut: 4000 });
      });
    }
  }

  markNotif(id: string) {
    this.pushNotification.markNotif(id).subscribe((data: any) => {
      if (data && data.id == id) {
        this.notif.map(n => { if (n.id == id) n.markAsRead = true; });
        this.checkReadNotif();
      }
    });
  }

  signout() {
    let nav = document.getElementById('dwmNavbar');
    if (nav!.classList.contains('show'))
      nav!.classList.remove('show');
    this.tokenDataServ.removeAll();
    this.toastr.success('You\'re successfully signed out', 'Success');
  }

  openURL(url: string, id: string) {
    let isUser = this.tokenDataServ.getToken() ? true : false;
    if (isUser) {
      this.offersSrv.offerClick(id).subscribe((data: any) => {
        if (data && data.id && data.offerId) {
          const appInsights = new ApplicationInsights({
            config: {
              instrumentationKey: instrumentationKey
            }
          });
          appInsights.loadAppInsights();
          appInsights.trackEvent({ name: `offerAnalytics|${id}|${this.user.id}` });
          setTimeout(() => {
            window.open(url, "_blank");
          }, 300);
        } else
          this.toastr.error(data.message || 'Something went wrong', 'Error!');
      });
    } else {
      this.router.navigate(['/signin'], { queryParams: { redirectTo: 'offers' } });
    }
  }
}
;