import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { ProgressBarService } from "../../progress-bar/progress-bar.service";

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private _progressBar: ProgressBarService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._progressBar.show()
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this._progressBar.hide();
      }
    }, (err: any) => {
      this._progressBar.hide();
    }));
  }
}
