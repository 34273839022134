import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { ProgressBarComponent } from './progress-bar.component';
import { ProgressBarService } from './progress-bar.service';



@NgModule({
  declarations: [ProgressBarComponent],
  imports: [
    CommonModule,
    MatProgressBarModule
  ],
  exports: [ProgressBarComponent],
  providers: [ProgressBarService]
})
export class ProgressBarModule { }
